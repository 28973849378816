<script setup lang="ts">
const auth = useAuth()
const router = useRouter()

watch(() => auth.loggedIn, (loggedIn) => {
  if (loggedIn) {
    router.replace('/app')
  }
}, { immediate: true })
</script>

<template>
  
  <AppWrapper>
    <div class="flex flex-col gap-4 items-center justify-center h-[80vh]">
      <BaseHeading size="2xl">LightKitch</BaseHeading>
      <BaseButton flavor="solid" color="primary" to="/api/login" external>
        Se connecter
      </BaseButton>
    </div>
  </AppWrapper>
</template>